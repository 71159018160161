import axios from 'axios'

export default {
  state: {
    navigation: []
  },
  mutations: {
    setNavigation (state, items) {
      state.navigation = items
    }
  },
  actions: {
    loadNavigation (context) {
      axios.get('/api/navigation/')
        .then(function (navigation) {
          context.commit('setNavigation', navigation)
        })
    }
  }
}
