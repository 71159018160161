let ONE_DAY_IN_MILLISECONDS = 86400000
let COOKIES_UNAVAILABLE_WARNING_BASE_DESCRIPTION = 'It was not possible to ' +
  '%a a cookie with the ' +
  'key: %k'

let cookie = {}

cookie.set = function (key, value, days, path, action) {
  action = action || 'set'
  if (isCookieAvailable()) {
    document.cookie = buildCookieData(key, value, days, path)
  } else {
    logCookiesUnavailableWarning(key, value, action)
  }
}

cookie.get = function (key) {
  let allCookies = getAllCookies()
  if (key) {
    return allCookies[key] ? allCookies[key] : null
  }
  return allCookies
}

cookie.remove = function (key, path) {
  cookie.set(key, '', -1, path, 'remove')
}

function isCookieAvailable () {
  return hasCookieSupport() && isCookieEnabled()
}

function hasCookieSupport () {
  return document && typeof document.cookie === 'string'
}

function isCookieEnabled () {
  let tomorrow = new Date(new Date().getTime() + ONE_DAY_IN_MILLISECONDS)
  document.cookie = 'test=test;path=/;expires=' + tomorrow.toUTCString()
  let isCookieEnabled = document.cookie.indexOf('test') > -1
  document.cookie = 'test=;path=/;expires=' + new Date(0).toUTCString()
  return isCookieEnabled
}

function buildCookieData (key, value, days, path) {
  let options = buildCookieOptions(days, path)
  let encodedValue = encodeURIComponent(value)
  return key + '=' + encodedValue + ';' + options.path + options.expiration
}

function buildCookieOptions (days, path) {
  return {
    expiration: buildExpirationValue(days),
    path: buildPathValue(path)
  }
}

function buildExpirationValue (days) {
  if (days) {
    let ONE_DAY = 24 * 60 * 60 * 1000
    let today = new Date()
    let expiration = new Date(today.setTime(today.getTime() + (days * ONE_DAY)))
    return 'expires=' + expiration.toUTCString()
  }
  return ''
}

function buildPathValue (path) {
  path = path || '/'
  return 'path=' + path + ';'
}

function getAllCookies () {
  let cookies = document.cookie.split(';')
  return convertCookiesToObject(cookies)
}

function convertCookiesToObject (cookies) {
  let cookiesObj = {}
  for (let i = 0; i < cookies.length; i++) {
    convertCurrentCookieToObject(cookies[i], cookiesObj)
  }
  return cookiesObj
}

function convertCurrentCookieToObject (cookie, cookies) {
  let key = buildCookieProperty(cookie.split('=')[0])
  let value = buildCookieProperty(cookie.split('=')[1])
  cookies[key] = decode(value)
}

function buildCookieProperty (property) {
  if (property) {
    return property.trim()
  }
  return ''
}

function logCookiesUnavailableWarning (key, value, action) {
  let description = buildCookiesUnavailableWarningDescription(key, value, action)
  console.warn('Cookies Unavailable', description)
  return cookie
}

function buildCookiesUnavailableWarningDescription (key, value, action) {
  let description = COOKIES_UNAVAILABLE_WARNING_BASE_DESCRIPTION
  if (action === 'set') {
    description += ' and value: ' + decode(value)
  }
  return description.replace('%a', action).replace('%k', key)
}

function decode (value) {
  return decodeURIComponent(value)
}

module.exports = cookie
