import axios from 'axios'

export default {
  state: {
    results: [],
    selectedHouse: []
  },
  getters: {
    getResultById: state => id => {
      return new Promise((resolve, reject) => {
        let house = state.results.find(house => house.id === id)
        if (typeof house !== 'undefined') {
          resolve(house)
        } else {
          reject(new Error('Could not find house search result by id: ' + id))
        }
      })
    }
  },
  mutations: {
    setResults (state, results) {
      state.results = results
    },
    selectHouse (state, house) {
      state.selectedHouse = house
    }
  },
  actions: {
    async selectResult ({ commit, getters }, houseId) {
      commit('selectHouse', await getters.getResultById(parseInt(houseId)))
    },
    loadHouses (context) {
      return new Promise((resolve, reject) => {
        axios.get('/api/kov/houses/')
          .then(function (response) {
            context.commit('setResults', response.data)
            resolve(response.data)
          })
          .catch(function (e) {
            reject(e)
          })
      })
    },
    loadRooms () {
      return new Promise((resolve, reject) => {
        axios.get('/api/kov/rooms/')
          .then(function (response) {
            resolve(response.data)
          })
      })
    }
  }
}
