import Vue from 'vue'
import Router from 'vue-router'
import routes from 'vue-auto-routing'
import { createRouterLayout } from 'vue-router-layout'
import store from '@/store'

Vue.use(Router)

const RouterLayout = createRouterLayout(layout => {
  return import('@/layouts/' + layout + '.vue')
})

// https://github.com/ktsn/vue-auto-routing

const router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 200)
    })
  },
  routes: [
    {
      path: '/',
      redirect: '',
      component: RouterLayout,
      children: routes,
      beforeEnter: (to, from, next) => {
        if (to.name !== 'uudis-id' && to.name !== 'maja-id-otsing' && to.fullPath !== '/' && to.fullPath !== '/otsing' && to.fullPath !== '/meist' && to.fullPath !== '/hooldus' && to.fullPath !== '/liitu' && store.state.user.user === null) {
          next({
            path: '/login',
            query: { redirect: to.fullPath }
          })
        } else {
          next()
        }
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/pages/__login__'),
      meta: {
        title: 'Login'
      },
      beforeEnter: (to, from, next) => {
        if (store.state.user.user !== null) {
          if (to.query && to.query.redirect) {
            next({
              path: to.query.redirect
            })
          } else {
            next({
              path: store.state.user.user.role === 'user' ? '/' : '/koduke'
            })
          }
        } else {
          next()
        }
      }
    },
    {
      path: '/koduke',
      redirect: '/koduke/ulevaade',
      name: 'koduke',
      component: () => import('@/layouts/default')
    },
    {
      path: '/kinnitus/:id',
      name: 'kinnitus',
      component: () => import('@/pages/__kinnitus__'),
      meta: {
        title: 'Kinnitus'
      }
    },
    {
      path: '/taust/:id',
      name: 'taust',
      component: () => import('@/pages/__taust__'),
      props: route => ({ expires: route.query.expires, signature: route.query.signature }),
      meta: {
        title: 'Taust ja harjumused'
      }
    },
    {
      path: '/parool/uus/:token',
      name: 'password.reset',
      component: () => import('@/pages/__uusparool__'),
      meta: {
        title: 'Parooli lähtestamine'
      }
    },
    {
      path: '/meist',
      name: 'Meist',
      component: () => import('@/pages/meist'),
      meta: {
        title: 'Meist'
      }
    },
    {
      path: '/liitu',
      name: 'Liitu',
      component: () => import('@/pages/liitu'),
      meta: {
        title: 'Liitu'
      }
    },
    {
      path: '/hooldus',
      name: 'Hooldus',
      component: () => import('@/pages/hooldus'),
      meta: {
        title: 'Hooldus'
      }
    },
    {
      path: '*',
      name: 'Page not found',
      component: () => import('@/pages/oops'),
      meta: {
        title: 'Lehte ei leitud'
      }
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  await store.restored
  if (to.meta.title && to.path.startsWith('/koduke')) {
    document.title = to.meta.title + ' - Koduke'
  } else if (to.meta.title) {
    document.title = '1Paigake - ' + to.meta.title
  } else {
    document.title = '1Paigake'
  }
  next()
})

export default router
