import axios from '@/http'
import store from '@/store'
// import Echo from '@/utils/echo'

const ACCESS_TOKEN_KEY = '1P_access_token'

const isLoggedMixin = {
  methods: {
    checkIfLogged () {
      // let header = getHeader()
      let accessToken = getAccessToken()
      return new Promise((resolve, reject) => {
        if (accessToken === '{{ session(\'token\') ?? \'\' }}' || accessToken === '') {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject('Unauthenticated')
        } else {
          axios.get('/api/user')
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              logout()
              reject(error.response)
            })
        }
      })
    }
  }
}

export default isLoggedMixin

export const logout = function () {
  return new Promise((resolve) => {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
    store.dispatch('user/setUser', null)
    resolve()
  })
}

export function getAccessToken () {
  let token = localStorage.getItem(ACCESS_TOKEN_KEY)
  if (!token) {
    token = document.getElementsByName('a-token')[0].content
    if (token) setAccessToken(token)
  }
  return token
}

export function setAccessToken (accessToken) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
}

export const getHeader = function () {
  const accessToken = getAccessToken()
  let header = {
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + accessToken,
  }
  if (typeof Echo !== 'undefined') {
    header['X-Socket-Id'] = Echo.socketId()
  }
  return header
}

