import Vue from 'vue'
import Vuex from 'vuex'
import App from '@/App'
import router from '@/router'
import '@/registerServiceWorker'
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
import store from '@/store'
import initEcho from '@/utils/echo'
import isLoggedMixin from '@/utils/auth'
import axios from '@/http'
import PortalVue from 'portal-vue'
import Lottie from 'vue-lottie'
import VueTimers from 'vue-timers'

import '@/assets/styles/quasar.styl'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/eva-icons/eva-icons.css'
import iconSet from 'quasar/icon-set/eva-icons'
import Quasar from 'quasar'
import langEt from '@/lang/et'

Vue.use(Quasar, {
  lang: langEt,
  iconSet: iconSet,
  config: {
    loadingBar: {
      color: 'accent',
      size: '3px'
    },
    notify: {
      timeout: 3000
      // position: 'top-right'
    }
  }
})

const files = require.context('@/components/', true, /\.vue$/i, 'lazy')
files.keys().map(key => {
  const componentName = key.split('/').pop().split('.')[0]
  Vue.component(componentName, () => files(key))
})

window.io = require('socket.io-client')
Vue.use(VueTimers)
Vue.component('lottie', Lottie)
Vue.use(PortalVue)

Vue.prototype.$http = axios

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.withCredentials = true;

Vue.use(Vuex)
Vue.use(Croppa, {
  componentName: 'croppa'
})

Vue.config.productionTip = false

new Vue({
  mixins: [isLoggedMixin],
  beforeCreate() {
    /* this.$q.loadingBar.$on('stop', () => {
      document.dispatchEvent(new Event('app.rendered'))
    }) */
  },
  created () {
    this.checkIfLogged()
      .then(response => {
        store.dispatch('user/setUser', response.data)
          .then(() => {
            initEcho()
              .then(echo => {
                echo.initChannels()
              })
          })
      })
      .catch(error => {
        console.log(error)
      })
  },
  mounted () {
    // Prevent blank screen in Electron builds
    let userAgent = navigator.userAgent.toLowerCase()
    if (userAgent.indexOf(' electron/') > -1) {
      this.$router.push('/')
    }
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
