<template>
  <div id="1p">
    <cookie-law v-if="!cookieAccepted" :visible="cookieBar" @close="cookieBar = false" />
    <router-view />
  </div>
</template>

<script>
import cookie from '@/utils/cookie'

export default {
  name: 'App',
  data () {
    return {
      cookieBar: true
    }
  },
  computed: {
    cookieAccepted () {
      if (cookie.get('cookie:accepted')) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
@import "assets/scss/style";
</style>
