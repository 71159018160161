import axios from '@/http'
import router from '@/router'
import Vue from 'vue'

function arraysIdentical(a, b) {
  var i = a.length;
  if (i != b.length) return false;
  while (i--) {
      if (a[i] !== b[i]) return false;
  }
  return true;
}

export default {
  state: {
    clients: [],
    selectedStatus: 'majas',
    selectedClient: [],
    selectedCareplan: [],
    component: 'ClientGrid',
    visibleColumns: ['photo', 'name', 'gender', 'search_info', 'queued_date', 'arrival_date', 'move', 'view'],
    selectedFilters: [],
    changedAt: null
  },
  getters: {
    getClientById: state => id => {
      return new Promise((resolve, reject) => {
        let client = state.clients.find(client => client.id === id)
        if (typeof client !== 'undefined') {
          resolve(client)
        } else {
          reject(new Error('Could not find client by id: ' + id))
        }
      })
    },
    clientAge (state) {
      let today = new Date()
      if (typeof state.selectedClient === 'undefined' || typeof state.selectedClient.personal_id === 'undefined') {
        return '-'
      }
      if (!Number.isInteger(parseInt(state.selectedClient.personal_id))) {
        return '-'
      }
      let birthDate = new Date('19' + state.selectedClient.personal_id.substring(1).slice(0, -4).split('').reduce((a, e, i) => a + e + (i % 2 === 1 ? '/' : ''), '').slice(0, -1))
      let age = today.getFullYear() - birthDate.getFullYear()
      let m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }
      return age
    }
  },
  mutations: {
    addClient (state, payload) {
      state.clients.push(payload)
    },
    reloadClient (state, payload) {
      // state.clients[payload.index] = payload.client
      Vue.set(state.clients, payload.index, payload.client)
    },
    changeAvatar (state, payload) {
      state.clients[payload.clientIndex].picture = payload.blob
    },
    moveClient (state, payload) {
      state.clients[payload.clientIndex].room = payload.room
    },
    setSelectedClient (state, client) {
      Vue.set(state, 'selectedClient', client)
    },
    setClients (state, clients) {
      Vue.set(state, 'clients', clients)
    },
    selectStatus (state, status) {
      state.selectedStatus = status
    },
    removeDocument (state, documentId) {
      state.selectedClient.documents = (state.selectedClient.documents.filter(document => document.id !== documentId))
    },
    selectComponent (state, component) {
      state.component = component
    },
    selectCareplan (state, careplan) {
      state.selectedCareplan = careplan
    },
    setClientProperty (state, payload) {
      state.clients[payload.clientIndex][payload.propertyName] = payload.propertyValue
    },
    saveTags (state, payload) {
      state.selectedClient.documents[payload.documentIndex].tags = payload.tags
      state.clients[payload.clientIndex].documents[payload.documentIndex].tags = payload.tags
    },
    setVisibleColumns (state, payload) {
      state.visibleColumns = payload
    },
    selectFilters (state, payload) {
      state.selectedFilters = payload
    }
  },
  actions: {
    setVisibleColumns ({ commit }, payload) {
      commit('setVisibleColumns', payload)
    },
    selectFilters ({ commit }, payload) {
      commit('selectFilters', payload)
    },
    addDiaryEntry (context, payload) {
      axios.post('/api/diary/' + payload.endpoint, payload.data)
        .then(response => {
          console.log(response)
        })
    },
    saveComms ({ state }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/client/comms', {
          clientId: state.selectedClient.id,
          ...payload
        })
          .then(response => {
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    deleteComms (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/client/comms/delete', payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    saveField ({ state }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/client/fields', {
          clientId: state.selectedClient.id,
          ...payload
        })
          .then(response => {
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    deleteField (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/client/fields/delete', payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    addClient ({ commit, state }, payload) {
      let endpoint = '/api/client'
      if (payload.house_id) {
        endpoint = '/api/queue'
      }
      commit('addClient', payload)
      return new Promise((resolve, reject) => {
        axios.post(endpoint, payload)
          .then(response => {
            let clientIndex = state.clients.findIndex(client => client.personalID === payload.personalID)
            state.clients[clientIndex].id = response.data
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    addClientStore ({ commit }, payload) {
      commit('addClient', payload)
    },
    changeAvatar ({ commit, state }, payload) {
      let clientIndex = state.clients.findIndex(client => client.id === payload.clientId)
      commit('changeAvatar', {
        clientIndex: clientIndex,
        blob: payload.blob
      })
      return new Promise((resolve, reject) => {
        let fd = new FormData()
        fd.append('id', payload.clientId)
        fd.append('image', payload.blob)
        axios.post('/api/client/image', fd)
          .then(r => {
            resolve(r)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    moveClient ({ commit, state, dispatch }, options) {
      return new Promise((resolve, reject) => {
        let clientIndex = state.clients.findIndex(client => client.id === parseInt(options.clientId))
        axios.post('/api/client/' + options.clientId + '/move', options)
          .then(response => {
            dispatch('loadClients')
            if (response.data.room) {
              commit('moveClient', { clientIndex: clientIndex, room: response.data.room })
            }
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    selectStatus ({ commit, state }, status) {
      if (state.selectedStatus !== status) {
        router.push({
          path: '/koduke/kliendid/#' + status.toLowerCase()
        })
        commit('selectStatus', status)
      }
    },
    selectComponent ({ commit, state }, component) {
      if (state.component !== component) {
        commit('selectComponent', component)
      }
    },
    loadCareplan ({ commit }, careplanId) {
      return new Promise((resolve, reject) => {
        axios.get('/api/careplan/' + careplanId)
          .then(response => {
            commit('selectCareplan', response.data)
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    loadClients ({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/clients')
          .then(response => {
            // if (state.clients.length === 0) {




            /*} else {
              dispatch('updateClients', response.data)
            }*/
            // dispatch('updateClients', response.data)

            try {
              if (!arraysIdentical(state.clients, response.data)) {
                commit('setClients', response.data)
              }
            } catch (e) {
              console.log(e)
            }
            resolve()
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    updateClients ({ commit, state }, clients) {
      clients.forEach(function (item, index, arr) {
        let clientIndex = state.clients.findIndex(client => client.id === parseInt(item['id']))
        if (clientIndex !== -1) {
          arr[index] = { ...state.clients[clientIndex], ...item }
        }
      })
      commit('setClients', clients)
    },
    reloadClient ({ commit, state, dispatch }, clientId) {
      return new Promise((resolve, reject) => {
        axios.get('/api/client/' + clientId)
          .then(response => {
            let clientIndex = state.clients.findIndex(client => client.id === parseInt(clientId))
            if (clientIndex !== -1) {
              let clientData = { ...state.clients[clientIndex], ...response.data }
              commit('reloadClient', {
                index: clientIndex,
                client: clientData
              })
              resolve(response.data)
            } else {
              resolve(dispatch('loadClients'))
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    reloadClientStore ({ commit, state, dispatch }, payload) {
      let clientIndex = state.clients.findIndex(client => client.id === parseInt(payload.id))
      if (clientIndex !== -1) {
        commit('reloadClient', {
          index: clientIndex,
          client: payload
        })
      } else {
        dispatch('loadClients')
      }
    },
    setClient ({ commit, state }, clientId) {
      return new Promise((resolve, reject) => {
        axios.get('/api/client/' + clientId)
          .then(response => {
            let clientIndex = state.clients.findIndex(client => client.id === parseInt(clientId))
            if (clientIndex !== -1) {
              commit('reloadClient', {
                index: clientIndex,
                client: response.data
              })
            }
            commit('setSelectedClient', response.data)
            resolve()
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    async selectClient ({ commit, getters }, clientId) {
      return new Promise(async (resolve, reject) => {
        try {
          let selectedClient = await getters.getClientById(parseInt(clientId))
          commit('setSelectedClient', selectedClient)
          resolve(selectedClient)
        } catch (e) {
          reject(e)
        }
      })
    },
    destroyDocument ({ state, commit }, documentId) {
      return new Promise((resolve, reject) => {
        axios.get('/api/client/' + state.selectedClient.id + '/document/destroy?id=' + documentId)
          .then(response => {
            commit('removeDocument', documentId)
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    destroyPlan ({ state }, documentId) {
      return new Promise((resolve, reject) => {
        axios.get('/api/client/' + state.selectedClient.id + '/careplan/destroy?id=' + documentId)
          .then(response => {
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    generateContract ({ state }, person) {
      return new Promise((resolve, reject) => {
        axios.post('/api/reservation-contract/', person)
          .then(response => {
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    saveChanges ({ dispatch }, client) {
      return new Promise((resolve, reject) => {
        axios.post('/api/client/save', client)
          .then(response => {
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    saveCareplan ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/client/' + payload.clientID + '/careplan/save', payload.plan)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveCheckpoint ({ state }) {
      return new Promise((resolve, reject) => {
        axios.post('/api/checkpoint', state.selectedCareplan)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getSelfDescription ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get('/api/careplan/' + payload.careplanID + '/description/load?expires=' + payload.expires + '&signature=' + payload.signature)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveSelfDescription ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/careplan/' + payload.careplanID + '/description/save', payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveTags ({ commit, state }, payload) {
      let clientIndex = state.clients.findIndex(client => client.id === parseInt(state.selectedClient.id))
      let documentIndex = state.selectedClient.documents.findIndex(document => document.id === parseInt(payload.id))
      commit('saveTags', {
        documentIndex: documentIndex,
        clientIndex: clientIndex,
        tags: payload.tags
      })
      axios.post('/api/tags', payload)
        .then(response => {
          commit('saveTags', {
            documentIndex: documentIndex,
            clientIndex: clientIndex,
            tags: response.data
          })
        })
    },
    saveAssets ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/client/' + payload.clientId + '/items/save', payload.data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
