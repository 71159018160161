import axios from 'axios'

export default {
  state: {
    connected: false,
    categories: []
  },
  mutations: {
    set (state, items) {
      state.categories = items
    },
    connect (state) {
      state.connected = true
    },
    disconnect (state) {
      state.connected = false
    }
  },
  actions: {
    load ({ commit }) {
      axios.get('/api/reminders')
        .then(function (response) {
          commit('set', response.data)
        })
    },
    save ({ state }) {
      axios.post('/api/reminders', state.categories)
        .then(function (response) {
        })
    },
    saveCustom ({ state }, reminders) {
      axios.post('/api/custom-reminders', reminders)
        .then(function (response) {
        })
    },
    connect ({ commit }) {
      commit('connect')
    },
    disconnect ({ commit }) {
      commit('disconnect')
    }
  }
}
