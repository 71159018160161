import axios from 'axios'

export default {
  state: {
    house: {
      id: null,
      name: null,
      description: '',
      picture: null,
      address: 'Aadress määramata',
      center: {},
      markers: [],
      personal: [
        {
          id: 1,
          role: 'Hooldusjuht',
          title: 'Juhan Sander',
          businessHours: {
            start: '10:00',
            end: '17:00'
          }
        },
        {
          id: 2,
          role: 'Tegevusjuht',
          title: 'Dolores Abernathy',
          businessHours: {
            start: '11:00',
            end: '15:00'
          }
        },
        {
          id: 3,
          role: 'Raamatupidaja',
          title: 'Clark Kent'
        },
        {
          id: 4,
          role: 'Kokk',
          title: 'Chuck Norris'
        },
        {
          id: 5,
          role: 'Tehniline personal',
          title: 'Jackie Chan'
        }
      ],
      shifts: [
        {
          resourceId: 1,
          title: '',
          start: '',
          end: ''
        },
        {
          resourceId: 2,
          title: '',
          start: '',
          end: ''
        },
        {
          resourceId: 3,
          title: '',
          start: '',
          end: ''
        },
        {
          resourceId: 4,
          title: '',
          start: '',
          end: ''
        },
        {
          resourceId: 5,
          title: '',
          start: '',
          end: ''
        }
      ]
    },
    shiftIsStarted: false,
    shiftStartedAt: '',
    shiftEndedAt: '',
    notices: [],
    tasks: [],
    services: [],
    targetgroups: [],
    users: [],
    selectedUser: [],
    roles: [],
    owner: '',
    phone: '',
    email_address: '',
    iban_account: '',
    recipient: '',
    reference: '',
    has_units: ''
  },
  getters: {
    personal: state => state.house.personal,
    tasks (state) {
      return state.tasks.sort((a, b) => a.done - b.done)
    }
  },
  mutations: {
    changeUser (state, payload) {
      state.users[payload.index] = payload
    },
    loadRoles (state, roles) {
      state.roles = roles
    },
    loadUsers (state, users) {
      state.users = users
    },
    selectAddress (state, address) {
      state.house.address = address.text
      state.house.center = address.position
      state.house.markers = []
      state.house.markers.push({ position: address.position })
    },
    toggleService (state, payload) {
      state.services[payload.categoryIndex].subName[payload.serviceIndex].checked = payload.checked
    },
    toggleGroup (state, payload) {
      state.targetgroups[payload.categoryIndex].subName[payload.targetIndex].checked = payload.checked
    },
    toggleTask (state, task) {
      let single = state.tasks.find(item => item.id === task)
      single.done = !single.done
      state.tasks.sort((a, b) => a.id - b.id)
    },
    addTask (state, task) {
      state.tasks.push({
        subject: task.subject,
        message: task.message,
        message_type: task.message_type,
        done: false,
        read: false
      })
    },
    deleteTask (state, task) {
      state.tasks.splice(task, 1)
    },
    loadTasks (state, tasks) {
      state.tasks = tasks
    },
    publishNotice (state, notices) {
      state.notices = notices
    },
    loadNotices (state, notices) {
      state.notices = notices
    },
    loadServices (state, services) {
      state.services = services
    },
    loadTargetGroups (state, targetgroups) {
      state.targetgroups = targetgroups
    },
    loadHouse (state, house) {
      state.house.id = house.id
      state.house.code = house.code
      state.house.name = house.name
      state.house.description = house.description
      state.house.address = house.location
      state.house.center = {
        lat: parseFloat(house.latitude),
        lng: parseFloat(house.longitude)
      }
      state.house.markers.push({
        position: state.house.center
      })
      state.house.picture = house.picture
      state.phone = house.phone
      state.iban_account = house.iban_account
      state.owner = house.owner
      state.email_address = house.email_address
      state.recipient = house.recipient_name
      state.reference = house.reference_number
      state.has_units = house.has_units
      state.units = house.units
    },
    updateHouseDescription (state, message) {
      state.house.description = message
    },
    startShift (state, time) {
      state.shiftIsStarted = true
      state.shiftStartedAt = time
    },
    stopShift (state, time) {
      state.shiftIsStarted = false
      state.shiftEndedAt = time
    },
    addShift (state, shift) {
      state.house.shifts.push({
        resourceId: shift.resourceId,
        title: shift.title,
        start: shift.start,
        end: shift.end
      })
    },
    selectUser (state, user) {
      state.selectedUser = user
    },
    changeRecipient (state, recipient) {
      state.recipient = recipient
    },
    changeReference (state, reference) {
      state.reference = reference
    },
    changeCode (state, code) {
      state.house.code = code
    },
    changeName (state, name) {
      state.house.name = name
    },
    changeOwner (state, owner) {
      state.owner = owner
    },
    changeEmail (state, email) {
      state.email_address = email
    },
    changePhone (state, phone) {
      state.phone = phone
    },
    changeIban (state, iban) {
      state.iban_account = iban
    },
    changeDepartment (state, hasUnits) {
      state.has_units = hasUnits
    },
    toggleRole (state, index) {
      state.selectedUser.rights[index].checked = !state.selectedUser.rights[index].checked
    },
    lockUser (state, id) {
      state.selectedUser.locked = !state.selectedUser.locked
    },
    addUser (state, user) {
      state.users.push(user)
    }
  },
  actions: {
    selectUser ({ commit }, user) {
      commit('selectUser', user)
    },
    changeDepartment ({ commit }, hasUnits) {
      commit('changeDepartment', hasUnits)
      axios.post('/api/house/change', {
        has_units: hasUnits
      })
        .then(response => {
          console.log(response.data)
        })
    },
    changeReference ({ commit }, reference) {
      commit('changeReference', reference)
      axios.post('/api/house/change', {
        reference: reference
      })
        .then(response => {
          console.log(response.data)
        })
    },
    changeRecipient ({ commit }, recipient) {
      commit('changeRecipient', recipient)
      axios.post('/api/house/change', {
        recipient: recipient
      })
        .then(response => {
          console.log(response.data)
        })
    },
    changeName ({ commit }, name) {
      commit('changeName', name)
      axios.post('/api/house/change', {
        name: name
      })
        .then(response => {
          console.log(response.data)
        })
    },
    changeCode ({ commit }, code) {
      commit('changeCode', code)
      axios.post('/api/house/change', {
        code: code
      })
        .then(response => {
          console.log(response.data)
        })
    },
    changeIban ({ commit }, iban) {
      commit('changeIban', iban)
      axios.post('/api/house/change', {
        iban_account: iban
      })
        .then(response => {
          console.log(response.data)
        })
    },
    changePhone ({ commit }, phone) {
      commit('changePhone', phone)
      axios.post('/api/house/change', {
        phone: phone
      })
        .then(response => {
          console.log(response.data)
        })
    },
    changeEmail ({ commit }, email) {
      commit('changeEmail', email)
      axios.post('/api/house/change', {
        email_address: email
      })
        .then(response => {
          console.log(response.data)
        })
    },
    changeOwner ({ commit }, owner) {
      commit('changeOwner', owner)
      axios.post('/api/house/change', {
        owner: owner
      })
        .then(response => {
          console.log(response.data)
        })
    },
    loadTargetGroups (context) {
      axios.get('/api/target-groups')
        .then(response => {
          context.commit('loadTargetGroups', response.data)
        })
        .catch(e => {
          console.log(e)
        })
    },
    loadNotices ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/notices')
          .then(response => {
            commit('loadNotices', response.data)
            resolve(response.data)
          })
      })
    },
    toggleGroup ({ state, commit }, payload) {
      let categoryIndex = state.targetgroups.findIndex(category => category.name === payload.name)
      let index = state.targetgroups[categoryIndex].subName.findIndex(targetgroup => targetgroup.id === payload.targetId)
      let checked = !state.targetgroups[categoryIndex].subName[index].checked
      axios.post('/api/target-groups/' + payload.targetId + '/toggle')
      commit('toggleGroup', {
        categoryIndex: categoryIndex,
        targetIndex: index,
        checked: checked
      })
    },
    selectAddress ({ commit }, address) {
      axios.post('/api/house/update', {
        id: address.id,
        address: address.text,
        coordinates: address.position
      }).then(response => {
      })
      commit('selectAddress', address)
    },
    toggleService ({ state, commit }, payload) {
      let categoryIndex = state.services.findIndex(category => category.name === payload.name)
      let serviceIndex = state.services[categoryIndex].subName.findIndex(service => service.id === payload.serviceId)
      let checked = !state.services[categoryIndex].subName[serviceIndex].checked
      commit('toggleService', {
        categoryIndex: categoryIndex,
        serviceIndex: serviceIndex,
        checked: checked
      })
      axios.post('/api/service/' + payload.serviceId + '/toggle')
    },
    publishNotice ({ commit }, payload) {
      axios.post('/api/notice', {
        selected: payload.selected,
        notice: payload.content
      })
        .then(response => {
          commit('publishNotice', response.data)
        })
    },
    loadHouse ({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/house')
          .then(response => {
            if (!response.data.name) {
              return
            }
            dispatch('loadServices')
            dispatch('loadTargetGroups')
            // this.dispatch('clients/loadClients')
            dispatch('loadTasks')
            dispatch('loadNotices')
            // this.dispatch('loadUsers')
            dispatch('loadRoles')
            commit('loadHouse', response.data)
            resolve()
          })
          .catch(e => {
            reject(e)
            console.log(e)
          })
      })
    },
    reloadHouse ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/house')
          .then(response => {
            if (!response.data.name) {
              return
            }
            commit('loadHouse', response.data)
            resolve()
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    saveFees (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/fees', payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    loadFees (context) {
      return new Promise((resolve, reject) => {
        axios.get('/api/fees')
          .then(response => {
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    loadBillables (context) {
      return new Promise((resolve, reject) => {
        axios.get('/api/billables/prices')
          .then(response => {
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    saveBillables (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/billables/prices', payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    deleteBillable (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/billables/delete', payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    updateHouseDescription ({ commit }, message) {
      axios.post('/api/house/update', {
        houseDescription: message
      }).then(response => {})
      commit('updateHouseDescription', message)
    },
    loadServices ({ commit }) {
      axios.get('/api/services')
        .then(response => {
          commit('loadServices', response.data)
        })
        .catch(e => {
          console.log(e)
        })
    },
    createRoomTask ({ commit, dispatch }, payload) {
      commit('addTask', {
        subject: payload.selectedAction === 'fix' ? 'Teade rikkest' : 'Telli koristus',
        message: payload.selectedRike,
        rooms: payload.selectedRooms,
        created_by: {},
        message_type: 'rooms',
        done: false,
        read: false
      })
      axios.post('/api/tasks', payload)
        .then(() => {
          dispatch('loadTasks')
        })
    },
    addTask ({ commit, dispatch }, task) {
      commit('addTask', {
        subject: task.subject,
        message: '',
        clients: task.message,
        created_by: {},
        message_type: 'clients',
        done: false,
        read: false
      })
      axios.post('/api/create-task', task)
        .then(response => {
          dispatch('loadTasks')
        })
    },
    loadTasks ({ commit }) {
      axios.get('/api/tasks')
        .then(response => {
          commit('loadTasks', response.data)
        })
    },
    toggleTask ({ commit }, task) {
      axios.post('/api/task/' + task + '/toggle')
      commit('toggleTask', task)
    },
    deleteTask ({ commit, state }, task) {
      let index = state.tasks.findIndex(t => t.id === task.id)
      axios.post('/api/task/' + task.id + '/delete')
      commit('deleteTask', index)
    },
    startShift ({ commit }, time) {
      commit('startShift', time)
    },
    stopShift ({ commit }, time) {
      commit('stopShift', time)
    },
    addShift ({ commit }, shift) {
      commit('addShift', shift)
    },
    loadRoles ({ commit }) {
      axios.get('/api/roles')
        .then(response => {
          commit('loadRoles', response.data)
        })
    },
    loadUsers ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/users')
          .then(response => {
            commit('loadUsers', response.data)
            resolve(response.data)
          })
      })
    },
    addOrChangeUser ({ commit, state }, payload) {
      if (typeof payload.id === 'undefined') {
        if (!payload.selected) {
          commit('addUser', payload)
        } else {
          commit('addUser', {
            name: payload.selected.label
          })
        }
      } else {
        let index = state.users.findIndex(user => user.id === payload.id)
        commit('changeUser', {
          index: index,
          ...payload
        })
      }
      return new Promise((resolve, reject) => {
        axios.post('/api/users/', payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    toggleRole ({ commit, state }, id) {
      let index = state.selectedUser.rights.findIndex(role => role.id === id)
      commit('toggleRole', index)
      return new Promise((resolve, reject) => {
        axios.post('/api/users/' + state.selectedUser.id + '/role/' + id)
          .then(response => {
            state.processing = false
            resolve(response)
          })
          .catch(e => {
            state.processing = false
            reject(e)
          })
      })
    },
    lockUser ({ commit, state }, id) {
      if (state.processing === true) {
        return
      }
      state.processing = true
      commit('lockUser', id)
      return new Promise((resolve, reject) => {
        axios.post('/api/user/' + id + '/lock')
          .then(response => {
            state.processing = false
            console.log(response.data)
          })
      })
    },
    removeUser ({ commit, state }, id) {
      if (state.processing === true) {
        return
      }
      state.processing = true
      //commit('removeUser', id)
      return new Promise((resolve, reject) => {
        axios.post('/api/house/user/' + id + '/remove')
          .then(response => {
            state.processing = false
            console.log(response.data)
          })
          .catch(e => {
            state.processing = false
            reject(e)
          })
      })
    },
    event (context, event) {
      axios.post('/api/event/', event)
    }
  }
}
