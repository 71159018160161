import axios from 'axios'

export default {
  state: {
    news: [],
    newsChannels: [],
    comments: []
  },
  getters: {
    getNewsById: state => id => {
      return new Promise((resolve, reject) => {
        let news = state.news.find(news => news.id === id)
        if (typeof news !== 'undefined') {
          resolve(news)
        } else {
          reject(new Error('Could not find news by id: ' + id))
        }
      })
    }
  },
  mutations: {
    updateNewsItem (state, payload) {
      let single = state.news.filter(item => item.id === payload.id)
      single[0].like_count = payload.like_count
    },
    like (state, payload) {
      let single = state.news.filter(item => item.id === payload.id)
      single[0].like = payload.likestatus
      single[0].like_count = payload.like_count
    },
    setNews (state, payload) {
      if (typeof payload.news !== 'undefined') {
        state.news = payload.news
      }
      if (typeof payload.channels !== 'undefined') {
        state.newsChannels = payload.channels
      }
      if (typeof payload.comments !== 'undefined') {
        state.comments = payload.comments
      }
    }
  },
  actions: {
    updateNewsItem (context, payload) {
      context.commit('updateNewsItem', {
        id: payload.id,
        like_count: payload.like_count
      })
    },
    loadNews (context) {
      function getNews () {
        return axios.get('/api/news')
      }
      function getNewsChannels () {
        return axios.get('/api/channels')
      }
      axios.all([getNews(), getNewsChannels()])
        .then(axios.spread(function (news, newsChannels) {
          context.commit('setNews', {
            news: news.data,
            channels: newsChannels.data
          })
        }))
        .catch(e => {
          console.log(e)
        })
    },
    loadSingleNews ({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        axios.get('/api/news/' + id)
          .then(response => {
            commit('setNews', {
              news: [response.data],
            })
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    loadComments ({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        axios.get('/api/comments/' + id)
          .then(response => {
            commit('setNews', {
              comments: response.data,
            })
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    like (context, payload) {
      axios.post('/api/news/like/' + payload.id, {
        like: payload.likestatus
      })
        .then(() => {
          context.commit('like', payload)
        })
    }
  }
}
