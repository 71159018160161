import axios from 'axios'

export default {
  state: {
    houses: [],
    kovs: [],
    users: [],
    selectedHouse: [],
    selectedUser: [],
    processing: false,
    partners: []
  },
  getters: {
    getResultById: state => id => {
      return new Promise((resolve, reject) => {
        let house = state.houses.find(house => house.id === id)
        if (typeof house !== 'undefined') {
          resolve(house)
        } else {
          reject(new Error('Could not find house search result by id: ' + id))
        }
      })
    },
    getUserById: state => id => {
      return new Promise((resolve, reject) => {
        let user = state.users.find(user => user.id === id)
        if (typeof user !== 'undefined') {
          resolve(user)
        } else {
          reject(new Error('Could not find user by id: ' + id))
        }
      })
    }
  },
  mutations: {
    changeHouse (state, payload) {
      state.houses[payload.index] = payload
    },
    addHouse (state, house) {
      state.houses.push(house)
    },
    clearSelected (state) {
      state.selectedHouse = []
    },
    setHouses (state, houses) {
      state.houses = houses
    },
    setUsers (state, users) {
      state.users = users
    },
    selectHouse (state, house) {
      state.selectedHouse = house
    },
    toggleContract (state, index) {
      state.houses[index].contract = !state.houses[index].contract
    },
    toggleRole (state, index) {
      state.selectedUser.rights[index].checked = !state.selectedUser.rights[index].checked
    },
    toggleKovContract (state, index) {
      state.kovs[index].contract = !state.kovs[index].contract
    },
    selectUser (state, user) {
      state.selectedUser = user
    },
    setKovs (state, kovs) {
      state.kovs = kovs
    },
    lockHouse (state, index) {
      state.houses[index].locked = !state.houses[index].locked
    },
    lockUser (state, id) {
      state.selectedUser.locked = !state.selectedUser.locked
    },
    setPartners (state, partners) {
      state.partners = partners
    },
    addPartner (state, payload) {
      state.selectedUser[payload.partner.type].push(payload.partner)
    },
    addUser (state, user) {
      state.users.push(user)
    }
  },
  actions: {
    selectUser ({ commit }, user) {
      commit('selectUser', user)
    },
    clearSelected ({ commit }) {
      commit('clearSelected')
    },
    async selectHouse ({ commit, getters }, houseId) {
      commit('selectHouse', await getters.getResultById(parseInt(houseId)))
    },
    loadKovs (context) {
      return new Promise((resolve, reject) => {
        axios.get('/api/admin/kovs/')
          .then(function (response) {
            context.commit('setKovs', response.data)
            resolve(response.data)
          })
          .catch(function (e) {
            reject(e)
          })
      })
    },
    loadHouses (context) {
      return new Promise((resolve, reject) => {
        axios.get('/api/admin/houses/')
          .then(function (response) {
            context.commit('setHouses', response.data)
            resolve(response.data)
          })
          .catch(function (e) {
            reject(e)
          })
      })
    },
    loadUsers (context) {
      return new Promise((resolve, reject) => {
        axios.get('/api/admin/users/')
          .then(function (response) {
            context.commit('setUsers', response.data)
            resolve(response.data)
          })
          .catch(function (e) {
            reject(e)
          })
      })
    },
    loadPartners ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/admin/partners/')
          .then(function (response) {
            commit('setPartners', response.data)
            resolve(response.data)
          })
          .catch(function (e) {
            reject(e)
          })
      })
    },
    addPartner ({ commit, state }, payload) {
      let index = state.users.findIndex(u => u.id === payload.id)
      commit('addPartner', {
        index: index,
        ...payload
      })
      return new Promise((resolve, reject) => {
        axios.post('/api/admin/partners/', payload)
          .then(response => {
            state.processing = false
            resolve(response)
          })
          .catch(e => {
            state.processing = false
            reject(e)
          })
      })
    },
    addOrChangeHouse ({ commit, state }, payload) {
      if (state.processing === true) {
        return
      }
      state.processing = true
      if (typeof payload.id === 'undefined') {
        commit('addHouse', payload)
      } else {
        let index = state.houses.findIndex(house => house.id === payload.id)
        commit('changeHouse', {
          index: index,
          ...payload
        })
      }
      return new Promise((resolve, reject) => {
        axios.post('/api/admin/houses/', payload)
          .then(response => {
            state.processing = false
            resolve(response)
          })
          .catch(e => {
            state.processing = false
            reject(e)
          })
      })
    },
    addOrChangeUser ({ commit, state }, payload) {
      if (state.processing === true) {
        return
      }
      state.processing = true
      if (payload.id === null) {
        commit('addUser', payload)
      } else {
        let index = state.users.findIndex(user => user.id === payload.id)
        commit('changeUser', {
          index: index,
          ...payload
        })
      }
      return new Promise((resolve, reject) => {
        axios.post('/api/admin/users/', payload)
          .then(response => {
            state.processing = false
            resolve(response)
          })
          .catch(e => {
            state.processing = false
            reject(e)
          })
      })
    },
    lockHouse ({ commit, state }) {
      if (state.processing === true) {
        return
      }
      state.processing = true
      let index = state.houses.findIndex(house => house.id === state.selectedHouse.id)
      commit('lockHouse', index)
      return new Promise((resolve, reject) => {
        axios.post('/api/houses/' + state.selectedHouse.id + '/lock')
          .then(response => {
            state.processing = false
            resolve(response)
          })
          .catch(e => {
            state.processing = false
            reject(e)
          })
      })
    },
    lockUser ({ commit, state }, id) {
      if (state.processing === true) {
        return
      }
      state.processing = true
      commit('lockUser', id)
      return new Promise((resolve, reject) => {
        axios.post('/api/user/' + id + '/lock')
          .then(response => {
            state.processing = false
            console.log(response.data)
          })
      })
    },
    toggleContract ({ commit, state }, id) {
      let index = state.houses.findIndex(house => house.id === id)
      commit('toggleContract', index)
      return new Promise((resolve, reject) => {
        axios.post('/api/houses/' + id + '/toggle')
          .then(response => {
            resolve(response)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    toggleKovContract ({ commit, state }, id) {
      let index = state.kovs.findIndex(kov => kov.id === id)
      commit('toggleKovContract', index)
      return new Promise((resolve, reject) => {
        axios.post('/api/kovs/' + id + '/toggle')
          .then(response => {
            state.processing = false
            resolve(response)
          })
          .catch(e => {
            state.processing = false
            reject(e)
          })
      })
    },
    toggleRole ({ commit, state }, id) {
      let index = state.selectedUser.rights.findIndex(role => role.id === id)
      commit('toggleRole', index)
      return new Promise((resolve, reject) => {
        axios.post('/api/users/' + state.selectedUser.id + '/role/' + id)
          .then(response => {
            state.processing = false
            resolve(response)
          })
          .catch(e => {
            state.processing = false
            reject(e)
          })
      })
    }
  }
}
