import axios from 'axios'

export default {
  state: {
    personal: [],
    selected: [],
    component: 'EmployeeGrid',
    visibleColumns: ['photo', 'full_name', 'phone', 'email', 'move', 'view'],
    selectedFilters: [],
  },
  getters: {
    getStaffById: state => id => {
      return new Promise((resolve, reject) => {
        let staff = state.personal.find(staff => staff.id === id)
        if (typeof staff !== 'undefined') {
          resolve(staff)
        } else {
          reject(new Error('Could not find staff by id: ' + id))
        }
      })
    }
  },
  mutations: {
    set (state, items) {
      state.personal = items
    },
    add (state, person) {
      state.personal.push(person)
    },
    change (state, payload) {
      state.personal[payload.index] = payload.person
    },
    select (state, person) {
      state.selected = person
    },
    move (state, payload) {
      state.personal[payload.index].status = payload.where
    },
    removeDocument (state, documentId) {
      state.selected.documents = (state.selected.documents.filter(document => document.id !== documentId))
    },
    setVisibleColumns (state, payload) {
      state.visibleColumns = payload
    },
    selectFilters (state, payload) {
      state.selectedFilters = payload
    },
    selectComponent (state, component) {
      state.component = component
    }
  },
  actions: {
    load ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/personal/')
          .then(function (personal) {
            commit('set', personal.data.staff)
            resolve(personal.data.staff)
          })
      })
    },
    async select ({ commit, getters }, staffId) {
      commit('select', await getters.getStaffById(parseInt(staffId)))
    },
    move ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        let staffIndex = state.personal.findIndex(person => person.id === payload.id)
        axios.post('/api/personal/' + payload.id + '/move', payload)
          .then(response => {
            if (payload.selected === 'eemal') {
              commit('move', { index: staffIndex, where: payload.selectedEemal.value })
            } else {
              commit('move', { index: staffIndex, where: payload.selected })
            }
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    reload ({ commit, state, dispatch }, id) {
      return new Promise((resolve, reject) => {
        let index = state.personal.findIndex(person => person.id === id)
        axios.get('/api/personal/' + id)
          .then(response => {
            if (index !== -1) {
              resolve(commit('change', {
                index: index,
                person: response.data
              }))
            } else {
              resolve(dispatch('load'))
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    save ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/personal/save', payload)
          .then(response => {
            resolve(response.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    addOrChange ({ commit, state }, payload) {
      if (payload.id === null) {
        commit('add', payload)
      } else {
        let index = state.personal.findIndex(person => person.id === payload.id)
        commit('change', {
          index: index,
          person: payload
        })
      }
      return new Promise((resolve, reject) => {
        axios.post('/api/personal/', payload)
          .then(response => {
            resolve(response)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    destroyDocument ({ state, commit }, documentId) {
      axios.get('/api/personal/' + state.selected.id + '/document/destroy?id=' + documentId)
      commit('removeDocument', documentId)
    },
    selectComponent ({ commit, state }, component) {
      if (state.component !== component) {
        commit('selectComponent', component)
      }
    },
    setVisibleColumns ({ commit }, payload) {
      commit('setVisibleColumns', payload)
    },
    selectFilters ({ commit }, payload) {
      commit('selectFilters', payload)
    }
  }
}
