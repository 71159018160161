import axios from 'axios'

export default {
  state: {
    query: [],
    results: [],
    selectedHouse: [],
    // targetgroups: [],
    searching: false,
    maxPrice: 0,
    minPrice: 0
  },
  getters: {
    getResultById: state => id => {
      return new Promise((resolve, reject) => {
        let house = state.results.find(house => house.id === id)
        if (typeof house !== 'undefined') {
          resolve(house)
        } else {
          reject(new Error('Could not find house search result by id: ' + id))
        }
      })
    }
  },
  mutations: {
    selectHouse (state, house) {
      state.selectedHouse = house
    },
    setResults (state, results) {
      state.results = results
    },
    addResult (state, result) {
      state.results.push(result)
    },
    setTargetGroups (state, targetgroups) {
      state.targetgroups = targetgroups
    },
    setQuery (state, query) {
      state.query = query
    },
    setMax (state, max) {
      state.maxPrice = max
    },
    setMin (state, min) {
      state.minPrice = min
    }
  },
  actions: {
    async selectResult ({ commit, getters }, houseId) {
      commit('selectHouse', await getters.getResultById(parseInt(houseId)))
    },
    setResult ({ commit }, house) {
      commit('addResult', house)
    },
    search ({ commit, state }, query) {
      commit('setQuery', query)
      state.searching = true
      return new Promise((resolve, reject) => {
        axios.post('/api/search', query)
          .then(response => {
            commit('setResults', response.data.results)
            commit('setMax', response.data.max)
            commit('setMin', response.data.min)
            state.searching = false
            resolve(response.data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },
    searchService ({ commit, state }, query) {
      commit('setQuery', query)
      state.searching = true
      return new Promise((resolve, reject) => {
        axios.post('/api/search-service', query)
          .then(response => {
            commit('setResults', response.data.results)
            commit('setMax', response.data.max)
            commit('setMin', response.data.min)
            state.searching = false
            resolve(response.data)
          })
          .catch(e => {
            console.log(e)
            reject(e)
          })
      })
    },
    loadTargetGroups (context) {
      return new Promise((resolve, reject) => {
        axios.get('/api/search-target-groups')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
