export default {
  state: {
    appVersion: JSON.parse(unescape(process.env.PACKAGE_JSON || '%7Bversion%3A0%7D')).version,
    connected: navigator.onLine,
    searchSidebarVisible: true,
    filterSidebarVisible: true,
    sidebarVisible: true,
    membershipDialog: false
  },
  getters: {
    connected: state => state.connected
  },
  mutations: {
    setConnected (state, payload) {
      state.connected = payload
    },
    toggleSidebar (state, value) {
      state.sidebarVisible = value
    },
    toggleSearchSidebar (state, value) {
      state.searchSidebarVisible = value
    },
    toggleFilterSidebar (state, value) {
      state.filterSidebarVisible = value
    },
    showMembershipDialog (state, value) {
      state.membershipDialog = value
    }
  },
  actions: {
    setConnected ({ commit }, payload) {
      commit('setConnected', payload)
    },
    toggleSidebar ({ state, commit }) {
      if (state.sidebarVisible === true) {
        commit('toggleSidebar', false)
      } else {
        commit('toggleSidebar', true)
      }
    },
    toggleSearchSidebar ({ state, commit }) {
      if (state.searchSidebarVisible === true) {
        commit('toggleSearchSidebar', false)
      } else {
        commit('toggleSearchSidebar', true)
      }
    },
    toggleFilterSidebar ({ state, commit }) {
      if (state.filterSidebarVisible === true) {
        commit('toggleFilterSidebar', false)
      } else {
        commit('toggleFilterSidebar', true)
      }
    },
    showMembershipDialog ({ commit }) {
      commit('showMembershipDialog', true)
    },
    hideMembershipDialog ({ commit }) {
      commit('showMembershipDialog', false)
    }
  }
}
