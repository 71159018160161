import store from '@/store'
import Echo from 'laravel-echo'

var echo

export default async function initEcho () {
  const token = await store.getters['user/token']

  let headers = {
    'Accept': 'application/json'
  }

  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  return new Promise((resolve, reject) => {
    if (typeof io === 'undefined') {
      reject('Socket.io client not found')
    }

    echo = new Echo({
      broadcaster: 'socket.io',
      host: (window.location.hostname === 'localhost' ? '1paigake.test' : window.location.hostname) + ':6002',
      auth: {
        headers: headers
      }
    })

    echo.initChannels = function () {
      echo.channel('public')
      .listen('.news.liked', (e) => {
        console.log(e)
      })

      echo.channel('public')
        .listen('CommentEvent', (e) => {
          store.dispatch('news/loadComments', e.comment)
        })

      if (typeof store.state.house !== 'undefined' && store.state.house.house !== null && store.state.house.house.id !== false) {
        echo.channel('house.' + store.state.house.house.id)
          .listen('.task.created', (e) => {
            store.dispatch('house/loadTasks')
          })
          .listen('ClientCreated', (e) => {
            store.dispatch('clients/addClientStore', e.client)
          })
          .listen('ClientsCreated', (e) => {
            store.dispatch('clients/loadClients')
          })
          .listen('ClientChanged', (e) => {
            store.dispatch('clients/reloadClientStore', e.client)
          })
      }
    }

    echo.leaveChannels = function () {
      if (typeof store.state.user !== 'undefined' && store.state.user.user !== null && store.state.user.user.id !== false) {
        echo.leave(`App.User.${store.state.user.user.id}`)
      }
      if (typeof store.state.house !== 'undefined' && store.state.house.house !== null && store.state.house.house.id !== false) {
        echo.leave('house.' + store.state.house.house.id)
      }
    }

    resolve(window.Echo = echo)
  })
}
