import axios from 'axios'
import router from '@/router'
import store from '@/store'
import jwt from 'jsonwebtoken'

axios.defaults.baseURL = process.env.VUE_APP_API_SERVER

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]')

var accessTokenRequest = null

function getAccessToken () {
  let decoded = jwt.decode(store.getters['user/token'])

  if (decoded !== null && decoded.exp - 240 <= (Date.now() / 1000).toFixed(0)) {
    /* if (accessTokenRequest === null) {
      accessTokenRequest = axios.post('/api/auth/refresh', {
        refresh_token: store.getters['user/refresh_token']
      })
        .then(response => {
          resetAccessTokenRequest()
          store.dispatch('user/setTokens', response.data)
          return response.data.token
        })
        .catch(error => {
          store.dispatch('user/clearTokens')
          // window.location.reload(true)
          return ''
        })
    }
    return accessTokenRequest*/
    store.dispatch('user/clearTokens')
    return ''
  }
  return store.getters['user/token']
}

function resetAccessTokenRequest() {
  accessTokenRequest = null
}

if (token) {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
} else {
  console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
}

axios.interceptors.request.use(async request => {
  let token

  if (!request.url.includes('login') && !request.url.includes('refresh') && !request.url.includes('register') && !request.url.includes('password')) {
    token = await getAccessToken()
  } else {
    token = store.getters['user/token']
  }

  if (token) {
    request.headers.common['Authorization'] = `Bearer ${token}`
  }

  if (typeof Echo !== 'undefined') {
    request.headers['X-Socket-Id'] = await Echo.socketId()
  }

  return request
}, error => {
  return Promise.reject(error)
})

axios.interceptors.response.use(response => {
  return response
}, error => httpFail(error))

function httpFail (error) {
  // Redirect if the backend asks it
  if (error.response && error.response.status === 503) {
    router.push('/hooldus')
    return Promise.reject(error)
  }

  // Reject on Laravel-driven validation errors
  if (error.response && error.response.status === 422) {
    return Promise.reject(error)
  }

  // Refresh tokens and reject to be further handled be the request initiator
  if (error.response && error.response.status === 419) {
    return refreshAppTokens().then(() => Promise.reject(error))
  }

  // If internal error
  if (error.message && !error.response) {
    // Due to a possible bug in Laravel Echo, whitelist Echo server error
    // See explanation above
    if (error.message === "Cannot read property 'socketId' of undefined") {
      // showError(error.message);
      return Promise.resolve(error)
    }

    // Display any other errors to the user and reject
    // showError(error.message)
    return Promise.reject(error)
  }

  /* if (error.request !== undefined && (error.request.responseURL.includes('refresh') || error.request.status === 401 && error.config.__isRetryRequest)) {
    store.dispatch('user/logOut', router.currentRoute.path)
  } else if (error.request !== undefined && error.request.status === 401) {
    error.config.__isRetryRequest = true
    return axios.request(error.config)
  } */

  // Redirect to log in page if unauthenticated
  if (error.response && error.response.config.url !== '/api/user' && error.response.config.url !== '/api/login' && error.response.status === 401) {
    const segments = router.currentRoute.path.split('/')
    const isAuth = segments.length > 1 && segments[1] === 'login'

    // If not on main page and not on /login page (change this block or remove accordingly to your app logic)
    if (router.currentRoute.path !== '/' && !isAuth) {
      store.dispatch('user/logOut', router.currentRoute.path)
    }
    return Promise.reject(error)
  }

  // Redirect if the backend asks it
  if (error.response && error.response.status === 402 && error.response.data.redirect) {
    router.push(error.response.data.redirect)
    return Promise.reject(error)
  }

  return Promise.reject(error)
}

function refreshAppTokens () {
  // Retrieve a new page with a fresh token
  axios.get('/')
    .then(({ data }) => {
      const wrapper = document.createElement('div')
      wrapper.innerHTML = data
      return wrapper.querySelector('meta[name=csrf-token]').getAttribute('content')
    })
    .then((token) => {
      axios.defaults.headers['X-CSRF-TOKEN'] = token
      document.querySelector('meta[name=csrf-token]').setAttribute('content', token)
    })
}

class Http extends axios {
  static post (url, data, config) {
    return new Promise((resolve, reject) => {
      axios.post(url, data, config)
        .then(response1 => resolve(response1))
        .catch((error1) => {
          console.warn('CSRF token is expired') // eslint-disable-line no-console
          // There is one more try for token mismatch error
          if (error1.response && error1.response.status === 419) {
            axios.post(url, data, config)
              .then((response2) => {
                const u = new window.URL(decodeURIComponent(location.href))
                location.href = `${location.origin}${u.searchParams.get('redirect')}`
                resolve(response2)
              })
              .catch(error2 => reject(error2))
          }
        })
    })
  }
}

export default Http

export const getHeaderToArray = function () {
  const accessToken = getAccessToken()
  return [
    { name: 'Accept', value: 'application/json' },
    { name: 'Authorization', value: 'Bearer ' + accessToken }
  ]
}
