import axios from 'axios'
import router from '@/router'
import {
  logout
} from '@/utils/auth.js'
// import echo from '@/utils/echo'

export default {
  state: {
    user: null,
    token: '',
    refresh_token: ''
  },
  getters: {
    token: state => {
      return state.token
    },
    refresh_token: state => {
      return state.refresh_token
    }
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    logOut (state) {
      state.user = null
      state.token = ''
      state.refresh_token = ''
    },
    changeImage (state, avatar) {
      state.user.avatar = avatar
    },
    setActive (state, active) {
      if (Number.isInteger(active)) {
        state.user.active_partner = parseInt(active)
      } else {
        state.user.active_partner = active
      }
    },
    setToken (state, token) {
      state.token = token
    },
    setRefreshToken (state, token) {
      state.refresh_token = token
    }
  },
  actions: {
    setUser ({ commit }, user) {
      return new Promise((resolve) => {
        resolve(commit('setUser', user))
      })
    },
    setTokens ({ commit }, tokens) {
      commit('setToken', tokens.token)
      commit('setRefreshToken', tokens.refresh_token)
    },
    clearTokens ({ commit }) {
      commit('setToken', '')
      commit('setRefreshToken', '')
    },
    reload ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/api/user')
          .then(response => {
            commit('setUser', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error.response)
          })
      })
    },
    logOut ({ commit }, to) {
      logout().then(function () {
        if (typeof Echo !== 'undefined') {
          Echo.leaveChannels()
        }
        commit('logOut')
        if (to) {
          router.push(`/login?redirect=${to}`)
        } else {
          router.push({ path: '/login' })
        }
        axios.post('/api/logout')
      })
    },
    verifyEmail (context, uuid) {
      return new Promise((resolve, reject) => {
        axios.post('/api/verify/', {
          code: uuid
        })
          .then(response => {
            resolve(response.data)
          })
      })
    },
    loadRepresentatives (context) {
      return new Promise((resolve, reject) => {
        axios.get('/api/representative')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    loadRegisterableHouses (context) {
      return new Promise((resolve, reject) => {
        axios.get('/api/representative?register=true')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editProfile ({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/user/edit', payload)
          .then(response => {
            commit('setUser', response.data.user)
            resolve(response.data)
          }).catch(error => {
            reject(error)
          })
      })
    },
    changeImage ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let fd = new FormData()
        fd.append('image', payload.blob)
        axios.post('/api/user/image', fd)
          .then(r => {
            commit('changeImage', r.data.avatar)
            resolve(r.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    switchActive ({ commit }, active) {
      return new Promise((resolve, reject) => {
        axios.post('/api/user/switch', {
          active: active
        })
          .then(response => {
            commit('setActive', active)
            resolve(response.data)
          }).catch(error => {
            reject(error)
          })
      })
    }
  }
}
