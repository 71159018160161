import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import modules from '@/store/modules'

Vue.use(Vuex)

export default new Vuex.Store({
  modules,
  plugins: [
    new VuexPersistence({
      key: '1P_store',
      reducer: (state) => ({
        user: state.user,
        house: state.house,
        rooms: state.rooms,
        clients: state.clients,
        personal: state.personal,
        notifications: state.notifications
      })
    }).plugin
  ]
})
