import axios from 'axios'
import Vue from 'vue'

export default {
  state: {
    rooms: [],
    selectedRoom: [],
    processing: false
  },
  getters: {
    getRoomById: state => roomId => {
      return new Promise((resolve, reject) => {
        let output = []
        let room
        switch (typeof roomId) {
          case 'object':
            let arrayLength = roomId.length
            if (arrayLength > 1) {
              for (var i = 0; i < arrayLength; i++) {
                try {
                  room = state.rooms.find(room => room.id === roomId[i].id)
                } catch (error) {
                  console.log(error)
                }
                if (typeof room !== 'undefined') {
                  output.push(room)
                }
              }
            } else {
              try {
                room = state.rooms.find(room => room.id === roomId[0].id)
              } catch (error) {
                console.log(error)
              }
              if (typeof room !== 'undefined') {
                output.push(room)
              }
            }
            break
          default:
            let input = parseInt(roomId)
            room = state.rooms.find(room => room.id === input)
            if (typeof room !== 'undefined') {
              output.push(room)
            }
            break
        }
        if (typeof output !== 'undefined') {
          if (output.length > 1) {
            resolve(output)
          } else {
            resolve(output[0])
          }
        } else {
          reject(new Error('Could not find room by id: ' + roomId))
        }
      })
    }
  },
  mutations: {
    addRoom (state, room) {
      state.rooms.push(room)
    },
    changeRoom (state, payload) {
      state.rooms[payload.roomIndex] = payload
    },
    changeDaycare (state, payload) {
      state.rooms[payload.roomIndex].availability = payload.availability
      state.rooms[payload.roomIndex].availability_date = payload.availability_date
      state.rooms[payload.roomIndex].max_people = payload.max_people
    },
    clearSelected (state) {
      state.selectedRoom = []
    },
    reloadRoom (state, payload) {
      Vue.set(state.rooms, payload.index, payload.room)
    },
    selectRoom (state, payload) {
      Vue.set(state, 'selectedRoom', payload)
    },
    setRooms (state, rooms) {
      state.rooms = rooms
    },
    deleteRoom (state, roomId) {
      state.rooms = (state.rooms.filter(room => room.id !== roomId))
    },
    setProcessing (state, processing) {
      state.processing = processing
    }
  },
  actions: {
    clearSelected ({ commit }) {
      commit('clearSelected')
    },
    async selectRoom ({ commit, getters, dispatch }, roomId) {
      let selectedRoom = await getters.getRoomById(roomId)
      if (selectedRoom !== undefined) {
        commit('selectRoom', selectedRoom)
      } else {
        dispatch('loadRoom', roomId)
          .then(roomData => {
            commit('selectRoom', roomData)
          })
      }
    },
    loadRoom ({ commit, state, dispatch }, roomId) {
      return new Promise((resolve, reject) => {
        axios.get('/api/room/' + roomId)
          .then(response => {
            let roomIndex = state.rooms.findIndex(room => room.id === parseInt(roomId))
            if (roomIndex !== -1) {
              let roomData = { ...state.rooms[roomIndex], ...response.data }
              commit('reloadRoom', {
                index: roomIndex,
                room: roomData
              })
              resolve(response.data)
            } else {
              resolve(dispatch('loadRooms'))
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    loadRooms ({ commit }) {
      axios.get('/api/rooms')
        .then(response => {
          commit('setRooms', response.data)
        })
        .catch(e => {
          console.log(e)
        })
    },
    changeImage (context, payload) {
      return new Promise((resolve, reject) => {
        let fd = new FormData()
        fd.append('id', payload.roomId)
        fd.append('image', payload.blob)
        axios.post('/api/rooms/image', fd)
          .then(r => {
            this.dispatch('rooms/loadRooms')
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    editDaycareOptions ({ commit, state }, payload) {
      let roomIndex = state.rooms.findIndex(room => room.id === payload.id)
      commit('changeDaycare', {
        roomIndex: roomIndex,
        ...payload
      })

      return new Promise((resolve, reject) => {
        axios.post('/api/daycare', payload)
          .then(response => {
            resolve(response)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    addOrChangeRoom ({ commit, state }, payload) {
      if (state.processing === true) {
        return
      }
      commit('setProcessing', true)
      if (typeof payload.id === 'undefined') {
        commit('addRoom', payload)
      } else {
        let roomIndex = state.rooms.findIndex(room => room.id === payload.id)
        commit('changeRoom', {
          roomIndex: roomIndex,
          ...payload
        })
      }
      return new Promise((resolve, reject) => {
        axios.post('/api/rooms', payload)
          .then(response => {
            commit('setProcessing', false)
            resolve(response)
          })
          .catch(e => {
            commit('setProcessing', false)
            reject(e)
          })
      })
    },
    deleteRoom ({ commit, state }, payload) {
      if (state.processing === true) {
        return
      }
      commit('setProcessing', true)
      commit('deleteRoom', payload)
      return new Promise((resolve, reject) => {
        axios.get('/api/rooms/' + payload + '/delete')
          .then(response => {
            commit('setProcessing', false)
            resolve(response)
          })
          .catch(e => {
            commit('setProcessing', false)
            reject(e)
          })
      })
    },
    switchRooms ({ commit, dispatch }, payload) {
      axios.post('/api/rooms/switch-rooms', payload)
        .then(() => {
          dispatch('loadRooms')
        })
    }
  }
}
