export default {
  isoName: 'et-ee',
  nativeName: 'Eesti',
  label: {
    clear: 'Tühjenda',
    ok: 'OK',
    cancel: 'Tühista',
    close: 'Sulge',
    set: 'Määra',
    select: 'Vali',
    reset: 'Tühjenda',
    remove: 'Kustuta',
    update: 'Uuenda',
    create: 'Loo',
    search: 'Otsi',
    filter: 'Filtreeri',
    refresh: 'Värskenda'
  },
  date: {
    days: 'Pühapäev_Esmaspäev_Teisipäev_Kolmapäev_Neljapäev_Reede_Laupäev'.split('_'),
    daysShort: 'P_E_T_K_N_R_L'.split('_'),
    months: 'Jaanuar_Veebruar_Märts_Aprill_Mai_Juuni_Juuli_August_September_Oktoober_November_Detsember'.split('_'),
    monthsShort: 'Jaan_Veeb_Mar_Apr_Mai_Juun_Juul_Aug_Sep_Okt_Nov_Dets'.split('_'),
    firstDayOfWeek: 1, // 0-6, 0 - Sunday, 1 Monday, ...
    format24h: true
  },
  table: {
    noData: 'Te pole veel siia midagi lisanud',
    noResults: 'Ei leitud midagi',
    loading: 'Laadimine...',
    selectedRecords: function (rows) {
      return rows === 1 ? '1 valitud.' : (rows === 0 ? 'Pole' : rows) + ' ühtegi valitud.'
    },
    recordsPerPage: 'Näita korraga:',
    allRows: 'Kõik',
    pagination: function (start, end, total) {
      return start + '-' + end + ', kokku ' + total
    },
    columns: 'Veerud'
  },
  editor: {
    url: 'URL',
    bold: 'Bold',
    italic: 'Italic',
    strikethrough: 'Strikethrough',
    underline: 'Underline',
    unorderedList: 'Unordered List',
    orderedList: 'Ordered List',
    subscript: 'Subscript',
    superscript: 'Superscript',
    hyperlink: 'Hyperlink',
    toggleFullscreen: 'Toggle Fullscreen',
    quote: 'Quote',
    left: 'Left align',
    center: 'Center align',
    right: 'Right align',
    justify: 'Justify align',
    print: 'Print',
    outdent: 'Decrease indentation',
    indent: 'Increase indentation',
    removeFormat: 'Remove formatting',
    formatting: 'Formatting',
    fontSize: 'Font Size',
    align: 'Align',
    hr: 'Insert Horizontal Rule',
    undo: 'Undo',
    redo: 'Redo',
    header1: 'Header 1',
    header2: 'Header 2',
    header3: 'Header 3',
    header4: 'Header 4',
    header5: 'Header 5',
    header6: 'Header 6',
    paragraph: 'Paragraph',
    code: 'Code',
    size1: 'Very small',
    size2: 'A bit small',
    size3: 'Normal',
    size4: 'Medium-large',
    size5: 'Big',
    size6: 'Very big',
    size7: 'Maximum',
    defaultFont: 'Default Font'
  },
  tree: {
    noNodes: 'No nodes available',
    noResults: 'No matching nodes found'
  }
}
